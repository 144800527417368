import axios from '@/axios/index'

// 根路径
const initPage = () =>
  axios({
    url: '/',
    method: 'GET',
  })

// 单一数据库查询
const singleDbSearch = info =>
  axios({
    url: '/single_db_search',
    method: 'GET',
    params: {
      info,
    },
  })

// 数据库对查询
const groupDbSearch = info =>
  axios({
    url: '/group_db_search',
    method: 'GET',
    params: {
      info,
    },
  })

// 注册
const register = (data) => axios({
  url: '/register',
  method: 'POST',
  data: data,
})

// 前端上传到文件 flask
// const uploadFile = () => axios({
//   url: "/upload",
//   method: "POST",
// });

const uploadFile = (data) => axios({
  url: '/upload',
  method: 'POST',
  data: data,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

// flask 上传到服务器
const uploadServer = (data) => axios({
  url: '/uploadServer',
  method: 'POST',
  data: data,
})

// New API
const getMappings = (userId) => axios({
  url: '/mapping/' + userId,
  method: 'GET',
})

const getFeedbackData = (userId) => axios({
  url: '/feedback/pull/' + userId,
  method: 'GET',
})

const submitFeedback = (data) => axios({
  url: '/feedback/submit',
  method: 'POST',
  data: data,
})

const downloadMappingFile = (userId) => axios({
  url: '/download/' + userId,
  method: 'GET',
  responseType: 'blob',
})

const runTask = (userId) => axios({
  url: '/run/' + userId,
  method: 'GET',
})

const uploadKGFiles = (data, config) => axios(Object.assign({
  url: '/upload',
  data: data,
  method: 'POST',
  headers: { },
}, config))

const getDefaultFileOptions = () => axios({
  url: '/default/option',
  method: 'GET',
})

const getTaskInfo = (userId) => axios({
  url: '/info/task/' + userId,
  method: 'GET',
})

const setDefaultFiles = (userId, optionId) => axios({
  url: '/default/upload',
  method: 'POST',
  params: {
    taskId: userId,
    id: optionId,
  },
})

const downloadDefaultFiles = (optionId, config) => axios(Object.assign({
  url: '/default/download/' + optionId,
  method: 'GET',
  responseType: 'blob',
  headers: { },
}, config))

const signUp = (userName, userEmail) => axios({
  url: '/register',
  params: {
    userName: userName,
    userEmail: userEmail,
  },
  method: 'POST',
})

const signIn = (taskId, userEmail) => axios({
  url: '/login',
  params: {
    taskId: taskId,
    email: userEmail,
  },
  method: 'POST',
})

// End
// run： 启动算法
const runAlg = (userId) => axios({
  url: '/runAlg',
  method: 'GET',
  params: {
    userId,
  },
})

// visualize: 可视化
const visualize = (userId) => axios({
  url: '/visualize',
  method: 'GET',
  params: {
    userId,
  },
})

// feedback
const getFeedback = (userId) => axios({
  url: '/feedback',
  method: 'GET',
  params: {
    userId,
  },
})

// feedbackSubmit
const feedbackSubmit = (data) => axios({
  url: '/feedbackSubmit',
  method: 'POST',
  data: data,
})

// log
const getLog = (userId) => axios({
  url: '/log/' + userId,
  method: 'GET',
  // params: {
  //   userId,
  // },
})

const getDoubleCilckNode = startNode =>
  axios({
    url: '/doubleClick',
    method: 'GET',
    params: {
      startNode,
    },
  })

const getOnceClickNode = startNode =>
  axios({
    url: '/onceClick',
    method: 'GET',
    params: {
      startNode,
    },
  })

const getStartNodeByRel = (startNode, relation) =>
  axios({
    url: '/startNodeByRel',
    method: 'GET',
    params: {
      startNode,
      relation,
    },
  })

const api = {
  initPage,
  singleDbSearch,
  groupDbSearch,
  getDoubleCilckNode,
  getOnceClickNode,
  getStartNodeByRel,
  uploadFile, // 上传文件
  register, // 注册
  uploadServer, // 上传到服务器
  runAlg, // run： 启动算法
  visualize, // visualize: 可视化
  getFeedback, // feedback
  getLog, // 日志
  feedbackSubmit, // feedbackSubmit
  getMappings,
  getFeedbackData,
  submitFeedback,
  downloadMappingFile,
  runTask,
  uploadKGFiles,
  getDefaultFileOptions,
  getTaskInfo,
  setDefaultFiles,
  downloadDefaultFiles,
  signUp,
  signIn,
}
export default api
